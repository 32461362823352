import Header from "./Header";
import Carrousel from "./Carrousel";

type Props = {
    carrouselVisible?: boolean
}

const NavbarWrapper = ({carrouselVisible}: Props) => {

    return <div className="container-fluid position-relative p-0">
        <Header />

        {carrouselVisible === true && <Carrousel/>}
    </div>

}
export default NavbarWrapper
