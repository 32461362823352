import {useTranslation} from "react-i18next";

const About = () => {
    const {t} = useTranslation()
  return <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container py-5">
          <div className="row g-5">
              <div className="col-lg-7">
                  <div className="section-title position-relative pb-3 mb-5">
                      <h5 className="fw-bold text-primary text-uppercase">{t("About Us")}</h5>
                      <h1 className="mb-0">
                          {t("A Complete Solution for managing displaysMoods-Display")}
                      </h1>
                  </div>
                  <p className="mb-4">
                      {t("the new way to display your content")}

                      <br />

                      {t("Display & promote what's important helped by our IA Use the dashboard to plan and broadcast your best products")}

                      <br />

                      {t("Create interactions")}
                      {t("Create interactions with your customers, and have a better feed back and insight")}


                      <br />

                      {t("Dynmanic catalog")}
                      {t("Create and manage your catalog with IA sorting feature to promote what you want first")}
                  </p>
                  <div className="row g-0 mb-3">
                      <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                          <h5 className="mb-3">
                              <i className="fa fa-check text-primary me-3" />
                              {t("New tech")}
                          </h5>
                          <h5 className="mb-3">
                              <i className="fa fa-check text-primary me-3" />
                              {t("Professional Staff")}
                          </h5>
                      </div>
                      <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                          <h5 className="mb-3">
                              <i className="fa fa-check text-primary me-3" />
                              {t("24/7 Support")}
                          </h5>
                          <h5 className="mb-3">
                              <i className="fa fa-check text-primary me-3" />
                              {t("Fair Prices")}
                          </h5>
                      </div>
                  </div>
                  <div
                      className="d-flex align-items-center mb-4 wow fadeIn"
                      data-wow-delay="0.6s"
                  >


                  </div>
                  <a
                      href="https://dashboard.moods-display.com"
                      className="btn btn-primary py-3 px-5 mt-3 wow zoomIn"
                      data-wow-delay="0.9s"
                  >
                      {t("Get started")}
                  </a>
              </div>
              <div className="col-lg-5" style={{ minHeight: 500 }}>
                  <div className="position-relative h-100">
                      <img
                          className="position-absolute w-100 h-100 rounded wow zoomIn"
                          data-wow-delay="0.9s"
                          src="img/about.jpg"
                          style={{ objectFit: "cover" }}
                      />
                  </div>
              </div>
          </div>
      </div>
  </div>

}
export default About
