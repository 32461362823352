import {useTranslation} from "react-i18next";

const Carrousel = () => {
    const {t} = useTranslation()
  return <div
      id="header-carousel"
      className="carousel slide carousel-fade"
      data-bs-ride="carousel"
  >
      <div className="carousel-inner">
          <div className="carousel-item active">
              <img className="w-100" src="img/carousel-1.jpg" alt="Image" />
              <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                  <div className="p-3" style={{ maxWidth: 900 }}>
                      <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                          {t("The new dymanic display manager for you shops , to promote the best for your customers powered with IA")}
                      </h5>
                      <h1 className="display-1 text-white mb-md-4 animated zoomIn">
                          Moods display
                      </h1>

                      <a
                          href="https://dashboard.moods-display.com"
                          className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight"
                      >
                          {t("Get started")}
                      </a>
                  </div>
              </div>
          </div>
          <div className="carousel-item">
              <img className="w-100" src="img/carousel-2.jpg" alt="Image" />
              <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                  <div className="p-3" style={{ maxWidth: 900 }}>
                      <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                          {t("dynamic display manager")}

                      </h5>
                      <h1 className="display-1 text-white mb-md-4 animated zoomIn">
                          Moods Display
                      </h1>

                      <a
                          href="https://dashboard.moods-display.com"
                          className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight"
                      >
                          {t("Get started")}
                      </a>
                  </div>
              </div>
          </div>
      </div>
      <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#header-carousel"
          data-bs-slide="prev"
      >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">{t("Previous")}</span>
      </button>
      <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#header-carousel"
          data-bs-slide="next"
      >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">{t("Next")}</span>
      </button>
  </div>

}
export default Carrousel
