import React from 'react';
import './App.css';
import Home from "./pages/Home";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import enFile from "./locales/en.json"
import frFile from "./locales/fr.json"
import esFile from "./locales/es.json"
import deFile from "./locales/de.json"
import itFile from "./locales/it.json"

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: {
            en: {
                translation: enFile
            },
            fr: {
                translation: frFile
            },
            es: {
                translation: esFile
            },
            de: {
                translation:deFile
            },
            it: {
                translation: itFile
            }
        },
        lng: "fr", // if you're using a language detector, do not define the lng option
        fallbackLng: "fr",

        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });

function App() {
    return (
        <Home/>
    );
}

export default App;
