import {ReactNode} from "react";
import Footer from "./Footer";

type  Props = {
  children: ReactNode
}

const DefaultLayout = ({children}: Props) => {
  return(
      <>
        {children}
          <Footer />
      </>
  )
}
export default DefaultLayout
