import {useTranslation} from "react-i18next";

const Pricing = () => {

    const {t} = useTranslation()
  return <div
      className="container-fluid py-5 wow fadeInUp"
      data-wow-delay="0.1s"
      style={{
          visibility: "visible",
          animationDelay: "0.1s",
          animationName: "fadeInUp"
      }}
  >
      <div className="container py-5">
          <div
              className="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ maxWidth: 600 }}
          >
              <h5 className="fw-bold text-primary text-uppercase">{t("Pricing Plans")}</h5>
              <h1 className="mb-0">
                  {t("We are Offering Competitive Prices for Our Clients")}
              </h1>
          </div>
          <div className="row g-0">
              <div className={"col-lg-4"}></div>
              {false && <div
                  className="col-lg-4 wow slideInUp"
                  data-wow-delay="0.6s"
                  style={{
                      visibility: "visible",
                      animationDelay: "0.6s",
                      animationName: "slideInUp"
                  }}
              >
                  <div className="bg-light rounded">
                      <div className="border-bottom py-4 px-5 mb-4">
                          <h4 className="text-primary mb-1">{t("Basic Plan")}</h4>
                          <small className="text-uppercase">{t("Simple plan for all Business")}</small>
                      </div>
                      <div className="p-5 pt-0">
                          <h1 className="display-5 mb-3">
                              <small
                                  className="align-top"

                              >
                                  $
                              </small>
                              49.00
                              <small
                                  className="align-bottom"

                              >
                                  / Month
                              </small>
                          </h1>
                          <div className="d-flex justify-content-between mb-3">
                              <span>HTML5 &amp; CSS3</span>
                              <i className="fa fa-check text-primary pt-1"/>
                          </div>
                          <div className="d-flex justify-content-between mb-3">
                              <span>Bootstrap v5</span>
                              <i className="fa fa-check text-primary pt-1"/>
                          </div>
                          <div className="d-flex justify-content-between mb-3">
                              <span>Responsive Layout</span>
                              <i className="fa fa-times text-danger pt-1"/>
                          </div>
                          <div className="d-flex justify-content-between mb-2">
                              <span>Cross-browser Support</span>
                              <i className="fa fa-times text-danger pt-1"/>
                          </div>
                          <a href="" className="btn btn-primary py-2 px-4 mt-4">
                              Order Now
                          </a>
                      </div>
                  </div>
              </div>}
              <div
                  className="col-lg-4 wow slideInUp"
                  data-wow-delay="0.3s"
                  style={{
                      visibility: "visible",
                      animationDelay: "0.3s",
                      animationName: "slideInUp"
                  }}
              >
                  <div
                      className="bg-white rounded shadow position-relative"
                      style={{ zIndex: 1 }}
                  >
                      <div className="border-bottom py-4 px-5 mb-4">
                          <h4 className="text-primary mb-1">Standard Plan</h4>
                          <small className="text-uppercase">Simple plan for all Business</small>
                      </div>
                      <div className="p-5 pt-0">
                          <h1 className="display-5 mb-3">
                              <small
                                  className="align-top"

                              >

                              </small>
                              19.00
                              <small
                                  className="align-bottom"

                              >
                                  {t("€/ Month")}
                              </small>
                          </h1>
                          <div className="d-flex justify-content-between mb-3">
                              <span>{t("Dynamic display")}</span>
                              <i className="fa fa-check text-primary pt-1" />
                          </div>
                          <div className="d-flex justify-content-between mb-3">
                              <span>{t("Catalog")}</span>
                              <i className="fa fa-check text-primary pt-1" />
                          </div>
                          <div className="d-flex justify-content-between mb-3">
                              <span>{t("Interactions")}</span>
                              <i className="fa fa-check text-primary pt-1" />
                          </div>
                          <div className="d-flex justify-content-between mb-2">
                              <span>{t("Insights")}</span>
                              <i className="fa fa-check text-primary pt-1" />
                          </div>
                          <div className="d-flex justify-content-between mb-2">
                              <span>{t("Annoucements")}</span>
                              <i className="fa fa-check text-primary pt-1" />
                          </div>
                          <a href="https://dashboard.moods-display.com" className="btn btn-primary py-2 px-4 mt-4">
                              {t("Get started")}
                          </a>
                      </div>
                  </div>
              </div>
              {false && <div
                  className="col-lg-4 wow slideInUp"
                  data-wow-delay="0.9s"
                  style={{
                      visibility: "visible",
                      animationDelay: "0.9s",
                      animationName: "slideInUp"
                  }}
              >
                  <div className="bg-light rounded">
                      <div className="border-bottom py-4 px-5 mb-4">
                          <h4 className="text-primary mb-1">Advanced Plan</h4>
                          <small className="text-uppercase">For Large Size Business</small>
                      </div>
                      <div className="p-5 pt-0">
                          <h1 className="display-5 mb-3">
                              <small
                                  className="align-top"

                              >
                                  $
                              </small>
                              149.00
                              <small
                                  className="align-bottom"

                              >
                                  / Month
                              </small>
                          </h1>
                          <div className="d-flex justify-content-between mb-3">
                              <span>HTML5 &amp; CSS3</span>
                              <i className="fa fa-check text-primary pt-1"/>
                          </div>
                          <div className="d-flex justify-content-between mb-3">
                              <span>Bootstrap v5</span>
                              <i className="fa fa-check text-primary pt-1"/>
                          </div>
                          <div className="d-flex justify-content-between mb-3">
                              <span>Responsive Layout</span>
                              <i className="fa fa-check text-primary pt-1"/>
                          </div>
                          <div className="d-flex justify-content-between mb-2">
                              <span>Cross-browser Support</span>
                              <i className="fa fa-check text-primary pt-1"/>
                          </div>
                          <a href="" className="btn btn-primary py-2 px-4 mt-4">
                              Order Now
                          </a>
                      </div>
                  </div>
              </div>}
          </div>
      </div>
  </div>



}
export default Pricing
