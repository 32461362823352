import {useTranslation} from "react-i18next";

const Features = () => {
    const {t} = useTranslation()
  return <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container py-5">
          <div
              className="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ maxWidth: 600 }}
          >
              <h5 className="fw-bold text-primary text-uppercase">{t("Why Choose Us")}</h5>
              <h1 className="mb-0">{t("We Are Here to Grow Your Business Exponentially")}</h1>
          </div>
          <div className="row g-5">
              <div className="col-lg-4">
                  <div className="row g-5">
                      <div className="col-12 wow zoomIn" data-wow-delay="0.2s">
                          <div
                              className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                              style={{ width: 60, height: 60 }}
                          >
                              <i className="fa fa-cubes text-white" />
                          </div>
                          <h4>{t("Best In Industry")}</h4>
                          <p className="mb-0">
                              {t("Our extensive portfolio of successful projects and satisfied clients demonstrates our ability to deliver exceptional results and drive exponential business growth.")}
                          </p>
                      </div>
                      <div className="col-12 wow zoomIn" data-wow-delay="0.6s">
                          <div
                              className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                              style={{ width: 60, height: 60 }}
                          >
                              <i className="fa fa-award text-white" />
                          </div>
                          <h4>{t("Innovative Solutions")}</h4>
                          <p className="mb-0">
                              {t("We leverage cutting-edge technology and innovative strategies to provide creative solutions that give your business a competitive edge in the market")}
                          </p>
                      </div>
                  </div>
              </div>
              <div
                  className="col-lg-4  wow zoomIn"
                  data-wow-delay="0.9s"
                  style={{ minHeight: 350 }}
              >
                  <div className="position-relative h-100">
                      <img
                          className="position-absolute w-100 h-100 rounded wow zoomIn"
                          data-wow-delay="0.1s"
                          src="img/feature.jpg"
                          style={{ objectFit: "cover" }}
                      />
                  </div>
              </div>
              <div className="col-lg-4">
                  <div className="row g-5">
                      <div className="col-12 wow zoomIn" data-wow-delay="0.4s">
                          <div
                              className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                              style={{ width: 60, height: 60 }}
                          >
                              <i className="fa fa-users-cog text-white" />
                          </div>
                          <h4>{t("Client-Centric Approach")}</h4>
                          <p className="mb-0">
                              {t("Your success is our priority. We take the time to understand your business goals and challenges, providing personalized services that align with your vision and drive exponential growth")}
                          </p>
                      </div>
                      <div className="col-12 wow zoomIn" data-wow-delay="0.8s">
                          <div
                              className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                              style={{ width: 60, height: 60 }}
                          >
                              <i className="fa fa-phone-alt text-white" />
                          </div>
                          <h4>{t("Data-Driven Strategies")}</h4>
                          <p className="mb-0">
                              {t("Our solutions are grounded in thorough market research and data analysis, ensuring informed decision-making and effective strategies that deliver measurable results.")}
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>

}
export default Features
