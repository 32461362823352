import { useTranslation} from "react-i18next";

const Footer = () => {
    const {t, i18n  }= useTranslation()

    const setLang = (event : any ,lng: string) => {
        i18n.changeLanguage(lng)

    }
  return <>
      {/* Footer Start */}
      <div
          className="container-fluid bg-dark text-light mt-5 wow fadeInUp"
          data-wow-delay="0.1s"
      >
          <div className="container">
              <div className="row gx-5">
                  <div className="col-lg-6 col-md-6 footer-about">
                      <div className="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4">
                          <a href="index.html" className="navbar-brand">
                              <h1 className="m-0 text-white">
                                  <i className="fa fa-user-tie me-2" />
                                  Moods Display
                              </h1>
                          </a>
                          <p className="mt-3 mb-4">
                              {t("Dynamic display manager")}
                          </p>

                      </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                      <div className="row gx-5">
                          <div className="col-lg-6 col-md-12 pt-5 mb-5">
                              <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                  <h3 className="text-light mb-0"></h3>
                              </div>
                              <div className="d-flex mb-2">
                                  <i className="bi bi-geo-alt text-primary me-2" />
                                  <p className="mb-0">15 rue Blanchard Fontenay aux rose  92260 France</p>
                              </div>
                              <div className="d-flex mb-2">
                                  <i className="bi bi-envelope-open text-primary me-2" />
                                  <p className="mb-0">contact@branholo.com</p>
                              </div>
                              <div className="d-flex mb-2">
                                  <i className="bi bi-telephone text-primary me-2" />
                                  <p className="mb-0">+33 7 53 12 78 23</p>
                              </div>
                              <div className="d-flex mt-4">
                                  <a className="btn btn-primary btn-square me-2" href="#">
                                      <i className="fab fa-twitter fw-normal" />
                                  </a>
                                  <a className="btn btn-primary btn-square me-2" href="#">
                                      <i className="fab fa-facebook-f fw-normal" />
                                  </a>
                                  <a className="btn btn-primary btn-square me-2" href="#">
                                      <i className="fab fa-linkedin-in fw-normal" />
                                  </a>
                                  <a className="btn btn-primary btn-square" href="#">
                                      <i className="fab fa-instagram fw-normal" />
                                  </a>
                              </div>
                          </div>
                          <div className="col-lg-6 col-md-12 pt-0 pt-lg-5 mb-5">
                              <div className="section-title section-title-sm position-relative pb-3 mb-4">

                              </div>
                              <div className="link-animated d-flex flex-column justify-content-start">

                                  <a className="text-light mb-2" href="#">
                                      <i className="bi bi-arrow-right text-primary me-2" />
                                      privacy
                                  </a>
                                  <a className="text-light" href="#">
                                      <i className="bi bi-arrow-right text-primary me-2" />
                                      terme of use
                                  </a>

                                  <ul className="flex items-center gap-8" style={{listStyleType:"none" , display:"flex" , gap: 10 , marginTop: "1rem"}}>
                                      <li>
                                          <a href="#" className="hover:text-primary" onClick={(event) => setLang(event ,"en")}>
                                              <img src={"/img/countries/united-states.svg"} alt={"English"} width={25} height={25} />
                                          </a>
                                      </li>
                                      <li>
                                          <a href="#" className="hover:text-primary" onClick={(event) => setLang(event ,"fr")}>
                                              <img src={"/img/countries/france.svg"} alt={"English"} width={25} height={25} />
                                          </a>
                                      </li>
                                      <li>
                                          <a href="#" className="hover:text-primary" onClick={(event) => setLang(event ,"de")}>
                                              <img src={"/img/countries/germany.svg"} alt={"English"} width={25} height={25} />
                                          </a>
                                      </li>
                                      <li>
                                          <a href="#" className="hover:text-primary" onClick={(event) => setLang(event ,"it")}>
                                              <img src={"/img/countries/italy.svg"} alt={"English"} width={25} height={25} />
                                          </a>
                                      </li>
                                      <li>
                                          <a href="#" className="hover:text-primary" onClick={(event) => setLang(event ,"es")}>
                                              <img src={"/img/countries/spain.svg"} alt={"English"} width={25} height={25} />
                                          </a>
                                      </li>
                                  </ul>
                              </div>
                          </div>

                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div className="container-fluid text-white" style={{ background: "#061429" }}>
          <div className="container text-center">
              <div className="row justify-content-end">
                  <div className="col-lg-8 col-md-6">
                      <div
                          className="d-flex align-items-center justify-content-center"
                          style={{ height: 75 }}
                      >
                          <p className="mb-0">
                              ©{" "}
                              <a className="text-white border-bottom" href="#">
                                  Moods Display
                              </a>
                              . All Rights Reserved.

                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      {/* Footer End */}
      {/* Back to Top */}
      <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
      >
          <i className="bi bi-arrow-up" />
      </a>
  </>

}
export default Footer
