import DefaultLayout from "../components/DefaultLayout";
import TopBar from "../components/TopBar";
import NavbarWrapper from "../components/NavbarWrapper";
import Facts from "../components/Facts";
import About from "../components/About";
import Features from "../components/Features";
import Services from "../components/Services";
import Quote from "../components/Quote";
import Pricing from "../components/Pricing";
import Team from "../components/Team";

const Home = () => {
  return(
      <DefaultLayout>
          <TopBar />
          <NavbarWrapper carrouselVisible={true} />
          <Facts />
          <About />
          <Features />
          <Services />
          <Pricing />


      </DefaultLayout>
  )
}
export default Home
