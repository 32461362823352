import {useTranslation} from "react-i18next";

const Header = () => {
    const {t} = useTranslation()
  return <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
      <a href="/" className="navbar-brand p-0">
          <h1 className="m-0"><img src={"img/logo.png"} width={50} height={50} style={{marginRight: "0.5rem"}} />Moods Display</h1>
      </a>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
          <span className="fa fa-bars"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto py-0">
              <a href="/" className="nav-item nav-link active">{t("Home")}</a>
              {false && <a href="about" className="nav-item nav-link">About</a>}
              {false && <a href="service" className="nav-item nav-link">Services</a>}

              { false && <a href="contact.html" className="nav-item nav-link">Contact</a>}
              {  <a href="https://dashboard.moods-display.com" className="nav-item nav-link">{t("Sign up")}</a>}
          </div>

          <a href="https://dashboard.moods-display.com" className="btn btn-primary py-2 px-4 ms-3">{t("Login")}</a>
      </div>
  </nav>
}
export default Header
