import {useTranslation} from "react-i18next";

const Services = () => {
    const {t} = useTranslation()
  return <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container py-5">
          <div
              className="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ maxWidth: 600 }}
          >
              <h5 className="fw-bold text-primary text-uppercase">{t("Our Services")}</h5>
              <h1 className="mb-0">{t("Custom IT Solutions for Your Successful Business")}</h1>
          </div>
          <div className="row g-5">
              <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                  <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                      <div className="service-icon">
                          <i className="fa fa-shield-alt text-white" />
                      </div>
                      <h4 className="mb-3">{t("Products display on TV")}</h4>
                      <p className="m-0">
                          {t("Display what you have so your customers will know what you have")}
                      </p>
                      <a className="btn btn-lg btn-primary rounded" href="">
                          <i className="bi bi-arrow-right" />
                      </a>
                  </div>
              </div>
              <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                  <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                      <div className="service-icon">
                          <i className="fa fa-chart-pie text-white" />
                      </div>
                      <h4 className="mb-3">{t("Annoucements")}</h4>
                      <p className="m-0">
                          {t("Promote for events & announcements in your shop")}
                      </p>
                      <a className="btn btn-lg btn-primary rounded" href="">
                          <i className="bi bi-arrow-right" />
                      </a>
                  </div>
              </div>
              <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                  <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                      <div className="service-icon">
                          <i className="fa fa-code text-white" />
                      </div>
                      <h4 className="mb-3">{t("Digital catalog")}
                      </h4>
                      <p className="m-0">
                          {t("Digital catalog and menu easy to access with QR code scan")}
                      </p>
                      <a className="btn btn-lg btn-primary rounded" href="">
                          <i className="bi bi-arrow-right" />
                      </a>
                  </div>
              </div>
              <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                  <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                      <div className="service-icon">
                          <i className="fab fa-android text-white" />
                      </div>
                      <h4 className="mb-3">{t("Quiz")}</h4>
                      <p className="m-0">
                          {t("Create interactions with your customers through quiz (comming soon)")}
                      </p>
                      <a className="btn btn-lg btn-primary rounded" href="">
                          <i className="bi bi-arrow-right" />
                      </a>
                  </div>
              </div>
              <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                  <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                      <div className="service-icon">
                          <i className="fa fa-search text-white" />
                      </div>
                      <h4 className="mb-3">{t("Ads proposal")}
                      </h4>
                      <p className="m-0">
                          {t("Generate an additional income by advertizing for near by non concurent shops")}
                      </p>
                      <a className="btn btn-lg btn-primary rounded" href="">
                          <i className="bi bi-arrow-right" />
                      </a>
                  </div>
              </div>
              <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                  <div className="position-relative bg-light rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-5">
                      <h3 className=" mb-3">{t("Stats & Feedback")}</h3>
                      <p className=" mb-3">
                          {t("Have a closer insight about your products and what your clients think")}
                      </p>
                  </div>
              </div>
          </div>
      </div>
  </div>

}
export default Services
